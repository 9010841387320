import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService, HttpReqMethod } from './api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  url: string;

  constructor(private apiService: ApiService) { }

  markInappropriate(data){
    this.url = 'message/markinappropriate';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.PUT.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  unmarkInappropriatePost(postId){
    this.url = 'message/unmark-post-inappropriate';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.PUT.toString(), {'entityId':postId}).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  unmarkInappropriateComment(commentId){
    this.url = 'message/unmark-comment-inappropriate';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.PUT.toString(), {'entityId':commentId}).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  unmarkInappropriateAdminPost(data){
    this.url = 'message/unmark-post-inappropriate';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.PUT.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  unmarkInappropriateAdminComment(data){
    this.url = 'message/unmark-comment-inappropriate';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.PUT.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Server error';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
